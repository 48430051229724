
import Vue from "vue";
import Api from "@/lib/api";
import { Rq, Table } from "@/lib/amdt";
import { objectCompare } from "@/lib/util";
import VForm from "@/lib/types/v-form";
import IProfile from "@/lib/interfaces/profile";
import { required, maxLength } from "@/lib/validations";
import IProfilePermission from "@/lib/interfaces/profile-permission";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import IPermission from "@/lib/interfaces/permission";
import ABtnGoBack from "@/components/elements/a-btn-go-back.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";

// ITabItem formato dos dados da tabela
interface ITableItem {
  id: number;
  permissaoNome: string;
  leitura: boolean;
  edicao: boolean;
  escrita: boolean;
  remocao: boolean;
  profilePermission: IProfilePermission;
}

export default Vue.extend({
  name: "ProfilePermissions",

  components: {
    vAlertMessage,
    ABtnGoBack,
    ASwitch,
    ABtnDialogConfirm,
    ADataTable,
    ACard,
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",
      teste: "",

      search: "",

      profile: {} as IProfile,
      permissions: new Array<IPermission>(),

      profilePermissions: new Array<IProfilePermission>(),

      updateProfilePermissions: [] as Array<ITableItem>,

      accessLevels: [
        "mdi-cancel",
        "mdi-eye-outline",
        "mdi-plus-thick",
        "mdi-pencil-outline",
        "mdi-trash-can-outline",
      ],

      headers: [
        { text: "MÓDULO", value: "permissaoNome" },
        { text: "NÍVEL DE ACESSO", value: "accessLevel", sortable: false },
        { text: "Status", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    cId(): string {
      return this.$route.params.id;
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },

    cBtnSaveDisabled(): boolean {
      return this.cUpdateData.length === 0 || !this.valid;
    },

    cUpdateData(): Array<Record<string, string>> {
      return this.updateProfilePermissions
        .map((item) => item.profilePermission)
        .filter((profilePermission) => !this.fnSkipRow(profilePermission))
        .filter(
          (profilePermission) =>
            !this.profilePermissions.some((pp) =>
              objectCompare(
                pp as unknown as Record<string, unknown>,
                profilePermission as unknown as Record<string, unknown>
              )
            )
        ) as unknown as Array<Record<string, string>>;
    },
  },

  mounted() {
    Promise.allSettled([
      this.getProfileById(),
      this.getProfilePermissions(),
      this.getPermissions(),
    ]).then(() => this.buildTable());
  },

  methods: {
    maxLength,
    required,

    fnSkipRow(pp: IProfilePermission): boolean {
      return (
        pp.id === "default" &&
        pp.leitura === "0" &&
        pp.edicao === "0" &&
        pp.escrita === "0" &&
        pp.remocao === "0" &&
        pp.desativado === "1"
      );
    },

    setPermission(item: ITableItem, values: Array<string>) {
      item.profilePermission.leitura = values.includes("leitura") ? "1" : "0";
      item.profilePermission.edicao = values.includes("edicao") ? "1" : "0";
      item.profilePermission.escrita = values.includes("escrita") ? "1" : "0";
      item.profilePermission.remocao = values.includes("remocao") ? "1" : "0";
    },

    getPermission(item: ITableItem) {
      const values = new Array<string>();

      if (item.profilePermission.leitura === "1") values.push("leitura");
      if (item.profilePermission.edicao === "1") values.push("edicao");
      if (item.profilePermission.escrita === "1") values.push("escrita");
      if (item.profilePermission.remocao === "1") values.push("remocao");

      return values;
    },

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("perfil_permissao.write");
        const tblPerfilPermissao = rq.addTable(new Table("perfil_permissao"));

        tblPerfilPermissao.addCols(...Object.keys(this.cUpdateData[0]));
        tblPerfilPermissao.addRowsFromObject(...this.cUpdateData);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        } else {
          await this.getProfilePermissions();
          this.buildTable();
          this.ret_msg = "Permissões atualizadas";
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getProfileById() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("profile.read", {
          id: this.cId,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        const profiles = rsp.getTable("perfil")?.getRowsObject() ?? [];
        if (profiles.length > 0) {
          this.profile = { ...profiles[0] };
        } else {
          this.ret_id = -1;
          this.ret_msg = "Perfil não encontrado";
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getProfilePermissions() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("perfil_permissao.read", {
          idProfile: this.cId,
          itemsPerPage: "-1",
          page: "1",
          desativado: "",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.profilePermissions =
          rsp.getTable("perfil_permissao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPermissions() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("permissao.read", {
          itemsPerPage: "-1",
          page: "1",
          desativado: "0",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.permissions = rsp.getTable("permissao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    buildTable() {
      this.updateProfilePermissions = this.permissions.map(
        (permission, index) => {
          let obj = {
            id: index,
            profilePermission: {
              id: "default",
              id_perfil: this.cId,
              id_permissao: permission.id,
              leitura: "0",
              edicao: "0",
              escrita: "0",
              remocao: "0",
              desativado: "1",
            },
            leitura: permission.leitura === "1",
            edicao: permission.edicao === "1",
            escrita: permission.escrita === "1",
            remocao: permission.remocao === "1",
            permissaoNome: permission.descricao,
          } as ITableItem;

          const profilePermission = this.profilePermissions.find(
            (profilePermission) =>
              profilePermission.id_perfil === this.cId &&
              profilePermission.id_permissao === permission.id
          );

          if (profilePermission)
            obj.profilePermission = { ...profilePermission };

          return obj;
        }
      );
    },
  },
});
